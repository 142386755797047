import React from "react";
import { graphql } from "gatsby";
import Page from "../templates/Page";

const ServicesPage = ({ data }) => {
  return <Page data={data} />;
};

export const pageQuery = graphql`
  query {
    page: markdownRemark(frontmatter: { path: { eq: "/skickat" } }) {
      ...pageFragment
    }
  }
`;

export default ServicesPage;
